import React from 'react';
import { Box, Text } from 'grommet';
import { MailOption, Phone } from 'grommet-icons';
import { StyledAnchor, StyledWrapper } from './styled';


export const Footer = () => (
  <StyledWrapper
    align='center'
    direction='row'
    height='xsmall'
    justify='end'
    pad='medium'
    gap='medium'
  >
    <Box
      border={{ color: 'white', side: 'right' }}
      pad='small'
    >
      <Text
        color='brand'
        size='xlarge'
        style={{ textShadow: '1px 1px grey' }}
      >
        REACH US
      </Text>
    </Box>
    <Box
      gap='small'
    >
      <Box
        align='center'
        direction='row'
        gap='small'
      >
        <MailOption
          color='#FFD201'
          size='medium'
        />
        <StyledAnchor
          color='white'
          href='mailto:info@boxstudio3d.com'
          label='info@boxstudio3d.com'
          weight='normal'
          style={{ textShadow: '1px 1px grey' }}
          size='medium'
        />
      </Box>
      <Box
        align='center'
        direction='row'
        gap='small'
      >
        <Phone
          color='#FFD201'
          size='medium'
        />
        <StyledAnchor
          color='white'
          href='tel:+923009470930'
          label='+92 300 9470930'
          weight='normal'
          style={{ textShadow: '1px 1px grey' }}
          size='medium'
        />
      </Box>
    </Box>
  </StyledWrapper>
);
