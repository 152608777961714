/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Box, Grommet } from 'grommet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GlobalStyle } from './styled';
import Header from '../header';
import { Footer } from '../footer';
import { theme } from '../../theme';
// import "./layout.css"

export const Layout = ({ showHeader, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Grommet theme={ theme }>
      <GlobalStyle />
      <Box
        justify='between'
      >
        {
          showHeader === true && <Header siteTitle={ data.site.siteMetadata.title } />
        }
        <main>
          <Box>{ children }</Box>
        </main>
        <Footer />
      </Box>
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  showHeader: PropTypes.bool.isRequired,
};

Layout.defaultProps = {
  showHeader: false,
};
