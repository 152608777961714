export const theme = {
  global: {
    colors: {
      brand: '#FFD201',
      grey: '#545560',
    },
    font: {
      family: "'Montserrat',sans-serif",
    },
  },
};
