import { createGlobalStyle } from 'styled-components';


export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
  a:hover {
    opacity: 0.9;
  }
`;
